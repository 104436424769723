import { persistReducer } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/es/stateReconciler/autoMergeLevel2';
import storage from 'redux-persist/es/storage';
import { ACTION_TYPES } from './Wallet.action';

const initialState = {
    wallet: '',
    chainId: '',
};

const walletReducer = (
    state = initialState,
    action: {
        type: string;
        payload: any;
    },
) => {
    switch (action.type) {
        case ACTION_TYPES.SET_WALLET:
            return {
                ...state,
                wallet: action.payload,
            };
        case ACTION_TYPES.SET_CHAIN_ID:
            return {
                ...state,
                chainId: action.payload,
            };
        case ACTION_TYPES.DELETE:
            return {
                ...state,
                chainId: '',
                wallet: '',
            };
        default:
            return state;
    }
};
const persistConfig = {
    key: 'networkReducer',
    storage,
    whitelist: ['wallet', 'chainId'],
    stateReconciler: autoMergeLevel2,
};

export default persistReducer(persistConfig, walletReducer as any);
