export const ACTION_TYPES = {
    ACTION_UPDATE_ACCOUNT: '[wallet account] action update wallet account',
    ACTION_UPDATE_ACCESS_TOKEN: '[wallet account] action update access token',
    ACTION_UPDATE_CODE_ERROR: '[wallet account] action update error code',
};

export interface IToken {
    address: string;
    token: string;
    nonce: number;
}

export interface WalletAccountState {
    address: string;
    token: string;
    nonce: number;
    codeError: number;
}

export interface updateWalletAccountType {
    type: string;
    payload: WalletAccountState;
}

export type AccountActions = updateWalletAccountType;
