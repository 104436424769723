import React, { useEffect, useState } from 'react';

import { useWeb3React } from '@web3-react/core';
import useEagerConnect from '../../../hooks/useEagerConnect';
import useInactiveListener from '../../../hooks/useInactiveListener';
import { network } from '../../../configs/Connect/Configs.wallets';
import { NetworkContextName } from '../../../app/App.constant';

const Loader = ({
    size = '16px',
    stroke = '#FFFFFF',
    ...rest
}: {
    size?: string;
    stroke?: string;
    [k: string]: any;
}) => {
    return (
        <svg
            className="animate-spin-slow"
            style={{ height: size, width: size }}
            viewBox="0 0 24 24"
            fill="none"
            {...rest}
        >
            <path
                d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 9.27455 20.9097 6.80375 19.1414 5"
                strokeWidth="2.5"
                strokeLinecap="round"
                strokeLinejoin="round"
                stroke={stroke}
            />
        </svg>
    );
};

export default function Web3ReactManager({ children }: { children: JSX.Element }) {
    const { active } = useWeb3React();
    const { active: networkActive, error: networkError, activate: activateNetwork } = useWeb3React(NetworkContextName);

    // try to eagerly connect to an injected provider, if it exists and has granted access already
    const triedEager = useEagerConnect();

    // after eagerly trying injected, if the network connect ever isn't active or in an error state, activate itd
    useEffect(() => {
        if (triedEager && !networkActive && !networkError && !active) {
            activateNetwork(network);
        }
    }, [triedEager, networkActive, networkError, activateNetwork, active]);

    // when there's no account connected, react to logins (broadly speaking) on the injected provider, if it exists
    useInactiveListener(!triedEager);

    // handle delayed loader state
    const [showLoader, setShowLoader] = useState(false);
    useEffect(() => {
        const timeout = setTimeout(() => {
            setShowLoader(true);
        }, 600);

        return () => {
            clearTimeout(timeout);
        };
    }, []);

    // on page load, do nothing until we've tried to connect to the injected connector
    if (!triedEager) {
        return null;
    }

    // if the account context isn't active, and there's an error on the network context, it's an irrecoverable error
    if (!active && networkError) {
        return (
            <div className="flex items-center justify-center h-80">
                <div className="text-secondary">
                    `Oops! An unknown error occurred. Please refresh the page, or visit from another browser or device`
                </div>
            </div>
        );
    }

    // if neither context is active, spin
    if (!active && !networkActive) {
        return showLoader ? (
            <div className="flex items-center justify-center h-80">
                <Loader />
            </div>
        ) : null;
    }

    return (
        <>
            <div />
            {children}
        </>
    );
}
