import autoMergeLevel2 from 'redux-persist/es/stateReconciler/autoMergeLevel2';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { ACTION_TYPES, WalletAccountState } from './WalletAccount.type';

const initialState: WalletAccountState = {
    address: '',
    token: '',
    nonce: 0,
    codeError: 0,
};

const walletAccountReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case ACTION_TYPES.ACTION_UPDATE_ACCESS_TOKEN: {
            const { address, token, nonce } = action.payload;
            return {
                ...state,
                address,
                token,
                nonce,
            };
        }
        case ACTION_TYPES.ACTION_UPDATE_CODE_ERROR: {
            const { statusCode } = action.payload;
            return {
                ...state,
                codeError: statusCode,
            };
        }
        default:
            return state;
    }
};

const persistConfig = {
    key: 'walletAccount',
    storage,
    whitelist: ['address', 'token', 'nonce'],
    stateReconciler: autoMergeLevel2,
};

export default persistReducer(persistConfig, walletAccountReducer as any);
