import React, { lazy } from 'react';
import { IRouteProps } from '..';

export const route = '/gallery';

const Element = React.lazy(() => import('../NftGalleryPage/NftGalleryPage'));

const NftGalleryPage: IRouteProps = {
    path: route,
    element: (
        <React.Suspense fallback={<></>}>
            <Element />
        </React.Suspense>
    ),
    name: 'NftGalleryPage',
};

export default NftGalleryPage;
