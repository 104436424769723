import React, { lazy } from 'react';
import { IRouteProps } from '..';

export const route = '/';

const Element = React.lazy(() => import('../LandingPagev2/LandingPagev2'));

const LandingPageV2Route: IRouteProps = {
    path: route,
    element: (
        <React.Suspense fallback={<></>}>
            <Element />
        </React.Suspense>
    ),
    name: 'LandingPageV2',
};

export default LandingPageV2Route;
