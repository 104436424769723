import React, { lazy } from 'react';
import { IRouteProps } from '..';

export const route = '/brown';

const Element = React.lazy(() => import('../BrownLandingPage/BrownLandingPage'));

const BrownLandingPageRoute: IRouteProps = {
    path: route,
    element: (
        <React.Suspense fallback={<></>}>
            <Element />
        </React.Suspense>
    ),
    name: 'BrownLandingPage',
};

export default BrownLandingPageRoute;
