import React from 'react';
import { ThemeProvider } from 'styled-components';
import { ThemedGlobalStyle, appTheme } from '../theme/Theme';
import { MainRoute } from '../modules';
import Modal from 'src/components/Modal';
import Loading from 'src/components/Loading';
import withApp from './App.enhance';
import { Web3ReactProvider } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';
import Web3ReactManager from '../components/Header/Web3ReactManager';
import { BrowserRouter } from 'react-router-dom';
import './App.css';
import './reset.scss';
import Web3ProviderNetwork from 'src/components/Web3ProviderNetwork';

function getLibrary(provider: any) {
    return new Web3Provider(provider);
}

function App() {
    return (
        <Web3ReactProvider getLibrary={getLibrary}>
            <Web3ProviderNetwork getLibrary={getLibrary}>
                <Web3ReactManager>
                    <ThemeProvider theme={appTheme(false)}>
                        <ThemedGlobalStyle />
                        <BrowserRouter>
                            <MainRoute />
                            <Modal />
                            <Loading />
                        </BrowserRouter>
                    </ThemeProvider>
                </Web3ReactManager>
            </Web3ProviderNetwork>
        </Web3ReactProvider>
    );
}

export default withApp(App);
